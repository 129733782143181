import { atom } from 'recoil';
import { PathDataProps } from '@/components/Demo/Paths/Phase/types';

export const PathsState = atom<PathDataProps[]>({
    key: 'PathsState',
    default: [
        {
            id: 1,
            name: `Back To You: Chronic Low Back`,
            phases: [
                {
                    id: 456,
                    phaseNumber: 1,
                    title: `HOAG 'chronic low back' Protocol Phase - 1`,
                    exerciseTotal: '5',
                    messageTotal: null,
                    trackOptions: ['Program complete time', 'Cat-Cow', 'Half Dead Bug - Left', 'Trunk Flexion (ROM)'],
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedTrack: 'Program complete time',
                    selectedType: 'Minutes',
                    selectedLogic: '<',
                    selectedNumber: '30',
                    messageProvider: false,
                },
                {
                    id: 8839,
                    title: `HOAG 'chronic low back' Protocol Phase - 2`,
                    exerciseTotal: '5',
                    messageTotal: null,
                    trackOptions: [
                        'Program complete time',
                        'Bridge',
                        'Pointer - Left',
                        'Trunk Flexion (ROM)',
                        'Trunk Extension (ROM)',
                    ],
                    selectedTrack: 'Program complete time',
                    phaseNumber: 2,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Minutes',
                    selectedLogic: '<',
                    selectedNumber: '25',
                    messageProvider: false,
                },
                {
                    id: 9249,
                    title: `HOAG 'chronic low back' Protocol Phase - 3`,
                    exerciseTotal: '5',
                    messageTotal: null,
                    trackOptions: [
                        'Program complete time',
                        'Bridge',
                        'Pointer - Left',
                        'Trunk Flexion (ROM)',
                        'Trunk Extension (ROM)',
                    ],
                    selectedTrack: 'Program complete time',
                    phaseNumber: 3,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Minutes',
                    selectedLogic: '<',
                    selectedNumber: '20',
                    messageProvider: false,
                },
            ],
        },
        {
            id: 2,
            name: `Track Acuity for Low Back Pain`,
            phases: [
                {
                    id: 9912,
                    title: `Back Pain Functional Scale (BPFS) - Weekly Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '12 questions (0-100)',
                    trackOptions: ['Survey Result'],
                    selectedTrack: 'Survey Result',
                    phaseNumber: 1,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '<',
                    selectedNumber: '40',
                    selectedType: 'Score',
                    messageProvider: true,
                },
                {
                    id: 88992,
                    title: `Trunk Flexion Assessment`,
                    exerciseTotal: '1',
                    messageTotal: null,
                    trackOptions: ['Program Complete Time', 'Standing Trunk Flexion'],
                    selectedTrack: 'Standing Trunk Flexion',
                    phaseNumber: 2,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '<',
                    selectedNumber: '20',
                    selectedType: 'Degrees',
                    messageProvider: true,
                },
            ],
        },
        {
            id: 3,
            name: `Post-Op Total Knee Replacement`,
            phases: [
                {
                    id: 829321,
                    title: `KOOS Jr - Weekly Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '7 questions (0-100)',
                    trackOptions: ['Survey Result'],
                    selectedTrack: 'Survey Result',
                    phaseNumber: 1,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '<',
                    selectedNumber: '35',
                    selectedType: 'Score',
                    messageProvider: true,
                },
                {
                    id: 8912377,
                    title: `TKR - Phase 1`,
                    exerciseTotal: '3',
                    messageTotal: null,
                    trackOptions: [
                        'Program Complete Time',
                        'Knee Extension/Flexion - Seated (ROM)',
                        'Long Arc Quads',
                        'Terminal Knee Flexion/Extension - Supine (ROM) - Left',
                        'Knee Flexion/Extension with Belt (ROM)',
                    ],
                    selectedTrack: 'Knee Extension/Flexion - Seated (ROM)',
                    phaseNumber: 2,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '>',
                    selectedNumber: '45',
                    selectedType: 'Degrees',
                    messageProvider: false,
                },
                {
                    id: 776312,
                    title: `TKR - Phase 2`,
                    exerciseTotal: '3',
                    messageTotal: null,
                    trackOptions: [
                        'Program Complete Time',
                        'Knee Extension/Flexion - Seated (ROM)',
                        'Long Arc Quads',
                        'Terminal Knee Flexion/Extension - Supine (ROM) - Left',
                        'Knee Flexion/Extension with Belt (ROM)',
                    ],
                    selectedTrack: 'Knee Extension/Flexion - Seated (ROM)',
                    phaseNumber: 3,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '>',
                    selectedNumber: '89',
                    selectedType: 'Degrees',
                    messageProvider: true,
                },
            ],
        },
        {
            id: 4,
            name: `Ongoing Fall Prevention`,
            phases: [
                {
                    id: 8329104,
                    title: `Pain/Mobility - Daily Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '3 questions',
                    trackOptions: ['Pain', 'Mobility', 'Fall'],
                    phaseNumber: 1,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedTrack: 'Pain',
                    selectedLogic: '>',
                    selectedNumber: '3',
                    selectedType: 'Score',
                    messageProvider: true,
                },
                {
                    id: 888321,
                    title: `Pain/Mobility - Daily Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '3 questions',
                    phaseNumber: 2,
                    trackOptions: ['Pain', 'Mobility', 'Fall'],
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedTrack: 'Mobility',
                    selectedLogic: '<',
                    selectedNumber: '2',
                    selectedType: 'Score',
                    messageProvider: false,
                },
                {
                    id: 4409832,
                    title: `Pain/Mobility - Daily Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '3 questions',
                    trackOptions: ['Pain', 'Mobility', 'Fall'],
                    selectedTrack: 'Fall',
                    phaseNumber: 3,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '=',
                    selectedNumber: '1',
                    selectedType: 'Score',
                    messageProvider: true,
                },
            ],
        },
        {
            id: 5,
            name: `Post-Op Total Hip Replacement`,
            phases: [
                {
                    id: 4536,
                    phaseNumber: 1,
                    title: `HOOS Jr - Weekly Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '6 questions (0-100)',
                    trackOptions: ['Survey Result'],
                    selectedTrack: 'Survey Result',
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Score',
                    selectedLogic: '<',
                    selectedNumber: '30',
                    messageProvider: true,
                },
                {
                    id: 88239,
                    title: `THR - Phase 1`,
                    exerciseTotal: null,
                    messageTotal: '2 Assessments',
                    trackOptions: ['Knee Extension/Flexion (AAROM)'],
                    selectedTrack: 'Knee Extension/Flexion (AAROM)',
                    phaseNumber: 2,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Degrees',
                    selectedLogic: '>',
                    selectedNumber: '90',
                    messageProvider: true,
                },
                {
                    id: 9249,
                    title: `THR - Phase 2`,
                    exerciseTotal: null,
                    messageTotal: '2 Assessments',
                    trackOptions: ['Knee Extension/Flexion (AAROM)'],
                    selectedTrack: 'Knee Extension/Flexion (AAROM)',
                    phaseNumber: 3,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Degrees',
                    selectedLogic: '>',
                    selectedNumber: '120',
                    messageProvider: true,
                },
            ],
        },
        {
            id: 6,
            name: `Post-Op Trigger Finger`,
            phases: [
                {
                    id: 33343434,
                    phaseNumber: 1,
                    title: `TF - Week 1`,
                    exerciseTotal: null,
                    messageTotal: null,
                    trackOptions: ['TAM'],
                    selectedTrack: 'TAM',
                    typeOptions: ['Degrees'],
                    logicOptions: ['<'],
                    selectedType: 'Degrees',
                    selectedLogic: '<',
                    selectedNumber: '202.5',
                    messageProvider: true,
                },
                {
                    id: 432423432,
                    title: `TF - Week 1`,
                    exerciseTotal: null,
                    messageTotal: null,
                    trackOptions: ['Quick DASH'],
                    selectedTrack: 'Quick DASH',
                    phaseNumber: 2,
                    typeOptions: ['<'],
                    logicOptions: ['>', '<'],
                    selectedType: 'Score',
                    selectedLogic: '<',
                    selectedNumber: '75',
                    messageProvider: true,
                },
            ],
        },
    ],
});

export const EditingPathsState = atom<PathDataProps[]>({
    key: 'EditingPathsState',
    default: [
        {
            id: 1,
            name: `Back To You: Chronic Low Back`,
            phases: [
                {
                    id: 456,
                    phaseNumber: 1,
                    title: `HOAG 'chronic low back' Protocol`,
                    exerciseTotal: '5',
                    messageTotal: null,
                    trackOptions: ['Program complete time', 'Cat-Cow', 'Half Dead Bug - Left', 'Trunk Flexion (ROM)'],
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedTrack: 'Program complete time',
                    selectedType: 'Minutes',
                    selectedLogic: '<',
                    selectedNumber: '30',
                    messageProvider: false,
                },
                {
                    id: 8839,
                    title: `HOAG 'chronic low back' Protocol`,
                    exerciseTotal: '5',
                    messageTotal: null,
                    trackOptions: [
                        'Program complete time',
                        'Bridge',
                        'Pointer - Left',
                        'Trunk Flexion (ROM)',
                        'Trunk Extension (ROM)',
                    ],
                    selectedTrack: 'Program complete time',
                    phaseNumber: 2,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Minutes',
                    selectedLogic: '<',
                    selectedNumber: '25',
                    messageProvider: false,
                },
                {
                    id: 9249,
                    title: `HOAG 'chronic low back' Protocol`,
                    exerciseTotal: '5',
                    messageTotal: null,
                    trackOptions: [
                        'Program complete time',
                        'Bridge',
                        'Pointer - Left',
                        'Trunk Flexion (ROM)',
                        'Trunk Extension (ROM)',
                    ],
                    selectedTrack: 'Program complete time',
                    phaseNumber: 3,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Minutes',
                    selectedLogic: '<',
                    selectedNumber: '20',
                    messageProvider: false,
                },
            ],
        },
        {
            id: 2,
            name: `Track Acuity for Low Back Pain`,
            phases: [
                {
                    id: 9912,
                    title: `Back Pain Functional Scale (BPFS) - Weekly Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '12 questions (0-100)',
                    trackOptions: ['Survey Result'],
                    selectedTrack: 'Survey Result',
                    phaseNumber: 1,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '<',
                    selectedNumber: '40',
                    selectedType: 'Score',
                    messageProvider: true,
                },
                {
                    id: 88992,
                    title: `Trunk Flexion Assessment`,
                    exerciseTotal: '1',
                    messageTotal: null,
                    trackOptions: ['Program Complete Time', 'Standing Trunk Flexion'],
                    selectedTrack: 'Standing Trunk Flexion',
                    phaseNumber: 2,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '<',
                    selectedNumber: '20',
                    selectedType: 'Degrees',
                    messageProvider: true,
                },
            ],
        },
        {
            id: 3,
            name: `Post-Op Total Knee Replacement`,
            phases: [
                {
                    id: 829321,
                    title: `KOOS Jr - Weekly Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '7 questions (0-100)',
                    trackOptions: ['Survey Result'],
                    selectedTrack: 'Survey Result',
                    phaseNumber: 1,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '<',
                    selectedNumber: '35',
                    selectedType: 'Score',
                    messageProvider: true,
                },
                {
                    id: 8912377,
                    title: `TKR`,
                    exerciseTotal: '3',
                    messageTotal: null,
                    trackOptions: [
                        'Program Complete Time',
                        'Knee Extension/Flexion - Seated (ROM)',
                        'Long Arc Quads',
                        'Terminal Knee Flexion/Extension - Supine (ROM) - Left',
                        'Knee Flexion/Extension with Belt (ROM)',
                    ],
                    selectedTrack: 'Knee Extension/Flexion - Seated (ROM)',
                    phaseNumber: 2,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '>',
                    selectedNumber: '45',
                    selectedType: 'Degrees',
                    messageProvider: true,
                },
                {
                    id: 776312,
                    title: `TKR`,
                    exerciseTotal: '3',
                    messageTotal: null,
                    trackOptions: [
                        'Program Complete Time',
                        'Knee Extension/Flexion - Seated (ROM)',
                        'Long Arc Quads',
                        'Terminal Knee Flexion/Extension - Supine (ROM) - Left',
                        'Knee Flexion/Extension with Belt (ROM)',
                    ],
                    selectedTrack: 'Knee Extension/Flexion - Seated (ROM)',
                    phaseNumber: 3,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '>',
                    selectedNumber: '89',
                    selectedType: 'Degrees',
                    messageProvider: true,
                },
            ],
        },
        {
            id: 4,
            name: `Ongoing Fall Prevention`,
            phases: [
                {
                    id: 8329104,
                    title: `Pain/Mobility - Daily Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '3 questions',
                    trackOptions: ['Pain', 'Mobility', 'Fall'],
                    phaseNumber: 1,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedTrack: 'Pain',
                    selectedLogic: '>',
                    selectedNumber: '3',
                    selectedType: 'Score',
                    messageProvider: false,
                },
                {
                    id: 888321,
                    title: `Pain/Mobility - Daily Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '3 questions',
                    phaseNumber: 2,
                    trackOptions: ['Pain', 'Mobility', 'Fall'],
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedTrack: 'Mobility',
                    selectedLogic: '<',
                    selectedNumber: '2',
                    selectedType: 'Score',
                    messageProvider: true,
                },
                {
                    id: 4409832,
                    title: `Pain/Mobility - Daily Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '3 questions',
                    trackOptions: ['Pain', 'Mobility', 'Fall'],
                    selectedTrack: 'Fall',
                    phaseNumber: 3,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedLogic: '=',
                    selectedNumber: '1',
                    selectedType: 'Score',
                    messageProvider: true,
                },
            ],
        },
        {
            id: 5,
            name: `Post-Op Total Hip Replacement`,
            phases: [
                {
                    id: 4536,
                    phaseNumber: 1,
                    title: `HOOS Jr - Weekly Text Survey`,
                    exerciseTotal: null,
                    messageTotal: '6 questions (0-100)',
                    trackOptions: ['Survey Result'],
                    selectedTrack: 'Survey Result',
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Score',
                    selectedLogic: '<',
                    selectedNumber: '30',
                    messageProvider: true,
                },
                {
                    id: 88239,
                    title: `THR - Phase 1`,
                    exerciseTotal: null,
                    messageTotal: '2 Assessments',
                    trackOptions: ['Knee Extension/Flexion (AAROM)'],
                    selectedTrack: 'Knee Extension/Flexion (AAROM)',
                    phaseNumber: 2,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Degrees',
                    selectedLogic: '>',
                    selectedNumber: '90',
                    messageProvider: true,
                },
                {
                    id: 9249,
                    title: `THR - Phase 2`,
                    exerciseTotal: null,
                    messageTotal: '2 Assessments',
                    trackOptions: ['Knee Extension/Flexion (AAROM)'],
                    selectedTrack: 'Knee Extension/Flexion (AAROM)',
                    phaseNumber: 3,
                    typeOptions: ['Degrees', '%', 'Score', 'Minutes', 'Hours', 'Days', 'Weeks'],
                    logicOptions: ['>', '<', 'Equals'],
                    selectedType: 'Degrees',
                    selectedLogic: '>',
                    selectedNumber: '120',
                    messageProvider: true,
                },
            ],
        },
        {
            id: 6,
            name: `Post-Op Trigger Finger`,
            phases: [
                {
                    id: 33343434,
                    phaseNumber: 1,
                    title: `TF - Week 1`,
                    exerciseTotal: null,
                    messageTotal: null,
                    trackOptions: ['TAM'],
                    selectedTrack: 'TAM',
                    typeOptions: ['Degrees'],
                    logicOptions: ['<'],
                    selectedType: 'Degrees',
                    selectedLogic: '<',
                    selectedNumber: '202.5',
                    messageProvider: true,
                },
                {
                    id: 432423432,
                    title: `TF - Week 1`,
                    exerciseTotal: null,
                    messageTotal: null,
                    trackOptions: ['Quick DASH'],
                    selectedTrack: 'Quick DASH',
                    phaseNumber: 2,
                    typeOptions: ['<'],
                    logicOptions: ['Score'],
                    selectedType: 'Score',
                    selectedLogic: '<',
                    selectedNumber: '75',
                    messageProvider: true,
                },
            ],
        },
    ],
});
